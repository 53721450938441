
import api from "../../api"
export const state = {

    companyBranchesList: [],
    companyBranchesData: {},
    errorMsg: null,
    successMsg: null,
}
export const mutations = {

    SET_COMPANY_BRANCHES_LIST(state, companyBranchesList) {
        state.companyBranchesList = companyBranchesList
    },
    SET_COMPANY_BRANCHES_DATA(state, companyBranchesData) {
        state.companyBranchesData = companyBranchesData
    },

    SET_ERROR_MSG(state, errorMsg) {
        state.errorMsg = errorMsg
    },
    SET_SUCCESS_MSG(state, successMsg) {
        state.successMsg = successMsg
    },


}

export const getters = {
    getCompanyBranchesList: state => state.companyBranchesList,
    companyBranchesData: state => state.companyBranchesData,
    errorMsg: state => state.errorMsg,
    successMsg: state => state.successMsg,
}

export const actions = {
    async fetchCompanyBranchesList({ commit }, companyId, page = 1) {
        await api({ requiresAuth: true }).get(`/companies/${companyId}/branches?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_COMPANY_BRANCHES_LIST', data)

            })
            .catch(error => {
                console.error(error)

            })
    },

    async getCompanyBranchesData({ commit }, { companyId: companyId, page: page = 1 }) {

        await api({ requiresAuth: true }).get(`/companies/${companyId}/branches?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_COMPANY_BRANCHES_DATA', data)
            })
            .catch(error => {
                console.error(error)

            })
    }
}